.App {
  text-align: center;
  background-color:  #f4f5f9; /*#eff2f5;*/
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  /*background-image: url("bg.svg");*/
}


.App textarea{
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  border: none !important;
  outline: none !important;
  min-width: 350px;
  min-height: 100px;
  max-width: 1000px;
  max-height: calc(70vh - 100px);


  white-space:pre-wrap;
  word-wrap: break-word;
}
/* .App textarea:focus-visible{
  border: none !important;
} */


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

[data-grammarly-shadow-root]{
  display: none !important;
}
.textAreaWrapper{
  position: relative;
  border-radius: 10px;
  /* overflow: hidden; */
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  background-color: #fff;
  padding: 4px 8px;
}
.topActionBar{
  color: #1b1746;
  display: block;
  height: 37px;
    line-height: 37px;
    position: absolute;
    top: -46px;
    width: 100%;
    left: 0;
    right: 0;
    font-weight: bold;
    font-size: 16px;
}
/* .topActionBar, .bottomActionBar{
  background-color: #f4f5f9;
  color: #000;
  display: block;
  height: 37px;
    line-height: 37px;
    display: none;;
} */

.pasteButton, .submitButton, .copyTextButton{
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  cursor: pointer;
  display: block;
  transition: all 90ms linear;
  border-radius: 5px;
  padding: 9px 18px;
}
.pasteButton:hover{
  background-color: #6456ff;
  color: #f4f5f9;
}
.pasteButton{
  background-color: #f4f5f9;
  color: #6456ff;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
}
.copyTextButton{
  position: absolute;
  bottom: -46px;
  left: 0;
  background-color: #6456ff;
  color: #fff;
  margin-bottom: -4px;
  margin-right: 4px;
}
.copyTextButton svg{
  margin-right: 5px;
  margin-bottom: -4px;
  display: inline-block;
  margin-left: -3px;
}
.submitButton{
  position: absolute;
  bottom: -50px;
  right: 0;
  background-color: #6456ff;
  color: #fff;
}
.submitButton.loading,
.copyTextButton.loading 
{
  background-color: #999 !important;
  cursor: default;
}
.copyTextButton:hover,
.submitButton:hover{
  background-color:#493cdc;
}
.pasteButton svg {
  margin-right: 5px;
  margin-bottom: -6px;
  display: inline-block;
}
.submitButton svg{
  margin-right: 7px;
  margin-bottom: -4px;
  margin-left: -2px;
  display: inline-block;
}

.shareButton:hover{
  background-color: #6456ff;
  color: #f4f5f9;
}
@media (max-width: 600px) {
  .App textarea{
    width: 80vw;
    min-width: 80vw;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
